import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"

const Info = () => {
  const query = useStaticQuery(graphql`
    {
      prismicHome {
        data {
          title {
            text
          }
          subtitle_1 {
            text
          }
          subtitle_2 {
            text
          }
          meta_title
          meta_image {
            url
          }
          meta_description
          email_link {
            url
            target
            type
          }
          linkedin_link {
            url
            type
            target
          }
          instagram_link {
            url
            type
            target
          }
        }
      }
    }
  `)

  const data = query.prismicHome.data
  if (!data) return null

  return (
    <section className="info">
      <div className="top">
        <h1>
          <Link to="/">{data.title.text}</Link>
        </h1>
        <h2>{data.subtitle_1.text}</h2>
        <h2>{data.subtitle_2.text}</h2>

        <div className="info__links">
          {data.email_link && (
            <a
              href={data.email_link.url}
              target={data.email_link.target ? data.email_link.target : "_self"}
            >
              <img src="/mail-outline.svg" alt="Email" />
            </a>
          )}

          {data.linkedin_link && (
            <a
              href={data.linkedin_link.url}
              target={
                data.linkedin_link.target ? data.linkedin_link.target : "_self"
              }
            >
              <img src="/logo-linkedin.svg" alt="LinkedIn" />
            </a>
          )}

          {data.instagram_link && (
            <a
              href={data.instagram_link.url}
              target={
                data.instagram_link.target
                  ? data.instagram_link.target
                  : "_self"
              }
            >
              <img src="/logo-instagram.svg" alt="Instagram" />
            </a>
          )}
        </div>
      </div>

      <div className="bottom">
        <footer>&copy; {new Date().getFullYear()}</footer>
      </div>
    </section>
  )
}

export default Info
